import { navigate } from "gatsby"
import React, { useContext } from "react"
import { fetchLeadersQuery } from "../../graphql/queries/fetchLeaders"
import BackButton from "../BackButton/BackButton"
import Button from "../Button"
import Notification from "../Notification"
import SectionHeader from "../SectionHeader"
import { LeaderCard } from "./LeaderCard/LeaderCard"
import * as styles from "./Leadership.module.scss"
import { remoteTeamsPageRoutesContext } from "../../data/context"

export const Leadership: React.FC = () => {
  const routes = useContext(remoteTeamsPageRoutesContext)

  const {
    allContentfulLeader: { nodes },
  } = fetchLeadersQuery()

  const vacanciesButton = (
    <Button
      text={"View vacancies"}
      color={"yellow"}
      onClick={() => navigate(routes.vacancies.to)}
    />
  )

  return (
    <div className={styles.main}>
      <div className={styles.container}>
        <div className={styles.headers}>
          <div className={styles.headersSectionHeader}>
            <SectionHeader main="leadership" secondary="v4team" />
          </div>
          <BackButton />
        </div>
        <div className={styles.leaders}>
          {nodes.map(node => (
            <LeaderCard
              key={node.fullName}
              fullName={node.fullName}
              title={node.title}
              profilePicture={node.profilePicture}
            />
          ))}
        </div>
        <Notification link={vacanciesButton} />
      </div>
    </div>
  )
}
