import classNames from "classnames"
import React from "react"
import * as styles from "./Notification.module.scss"

type NotificationProps = {
  link: JSX.Element
  className?: string
}

export const Notification: React.FC<NotificationProps> = ({ link, className }) => (
  <div className={classNames(styles.container, className)}>
    <div className={styles.content}>
      <div className={styles.heading}>
        Start Living Your Life - Join Us
      </div>
      <div className={styles.text}>
        Our greatest Value is our Team - ultimate professionals, passionate about their work and pleasure to work with.
      </div>
    </div>
    <div className={styles.link}>
      {link}
    </div>
  </div>
)
