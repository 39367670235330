import classNames from "classnames"
import { domAnimation, LazyMotion, m } from "framer-motion"
import React from "react"
import * as styles from "./Button.module.scss"

type ButtonColors = "yellow" | "violet"

type ButtonProps = {
  text: string
  color: ButtonColors
  className?: string
  onClick?: () => void
  mail?: boolean
}

export const Button: React.FC<ButtonProps> = props => {
  const { text, color = "yellow", className, onClick } = props
  return (
    <LazyMotion features={domAnimation}>
      <m.button
        onClick={onClick}
        className={classNames(styles.button, styles[color], className)}
        type="button"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        {text}
      </m.button>
    </LazyMotion>
  )
}
