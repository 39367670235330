import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import * as styles from "./LeaderCard.module.scss"

export interface LeaderCard {
  fullName: string
  title: string
  profilePicture: { gatsbyImageData: IGatsbyImageData }
}

export const LeaderCard: React.FC<LeaderCard> = ({
  fullName,
  title,
  profilePicture,
}) => {
  return (
    <div>
      <GatsbyImage image={profilePicture.gatsbyImageData} alt={fullName} className={styles.profilePicture} />
      <h4 className={styles.fullName}>{fullName}</h4>
      <h5 className={styles.title}>{title}</h5>
      <div></div>
    </div>
  )
}
