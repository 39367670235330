import { graphql, useStaticQuery } from "gatsby"
import { IGatsbyImageData } from "gatsby-plugin-image"

interface Leader {
  fullName: string
  title: string
  profilePicture: { gatsbyImageData: IGatsbyImageData }
}

interface LeadersQueryData {
  allContentfulLeader: {
    nodes: Leader[]
  }
}

export const fetchLeadersQuery = (): LeadersQueryData => {
  const data = useStaticQuery(graphql`
    query LeadersQuery {
      allContentfulLeader(sort: {fields: order, order: ASC}) {
        nodes {
          fullName
          title
          profilePicture {
            gatsbyImageData
          }
        }
      }
    }
  `)
  return data
}
