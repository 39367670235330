import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/SEO"
import { Leadership as LeadershipPage } from "../components/Leadership/Leadership"

const Leadership: React.FC = () => (
  <Layout>
      <SEO
        title="V4Scale technologies"
        description="V4Scale is an Israeli company that expands the local R&D capabilities of leading Israeli and US technology companies with their diverse and skilled remote workforce from all over the world."
      />
      <LeadershipPage />
  </Layout>
)
export default Leadership
